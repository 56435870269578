import React, { Component, Fragment } from "react"

import { withAuth0 } from "@auth0/auth0-react"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Table from "react-bootstrap/Table"
import { useIntl } from "react-intl"

import Colon from "./colon"
import Error from "./error"
import Loading from "./loading"
import Space from "./space"

class WalletTransactions extends Component {
  constructor(props) {
    super(props)
    this.refresh = this.refresh.bind(this)
    this.state = { loaded: false, error: null, data: null }
  }

  componentDidMount() {
    this.timer = setInterval(this.refresh, 60 * 1000)
    this.refresh()
  }

  componentWillUnmount() {
    clearTimeout(this.timer)
  }

  refresh() {
    this.props.auth0
      .getAccessTokenSilently({
        audience: process.env.GATSBY_AUTH0_AUDIENCE,
        scope: process.env.GATSBY_AUTH0_SCOPE,
      })
      .then(accessToken => {
        const url = new URL("/wallet/transactions", process.env.GATSBY_API_URL)
        const params = {}
        Object.keys(params).forEach(key =>
          url.searchParams.append(key, params[key])
        )
        fetch(url, {
          method: "GET",
          headers: { Authorization: `Bearer ${accessToken}` },
        })
          .then(response => {
            if (response.ok) {
              response.json().then(json => {
                this.setState({ loaded: true, data: json })
              })
            } else {
              response.text().then(text => {
                this.setState({ loaded: true, error: text })
              })
            }
          })
          .catch(error => {
            this.setState({
              loaded: true,
              error: error,
            })
          })
      })
  }

  render() {
    const { loaded, error, data } = this.state
    if (!loaded) {
      return <Loading />
    } else if (error) {
      return <Error error={error} />
    } else {
      return (
        <Row>
          <Col className="col-auto">
            <List items={data} />
          </Col>
        </Row>
      )
    }
  }
}

function List(props) {
  const intl = useIntl()
  return (
    <Table striped bordered hover responsive>
      <thead className="thead-dark">
        <tr>
          <th />
          <th>{intl.formatMessage({ id: "Created" })}</th>
          <th>{intl.formatMessage({ id: "Type" })}</th>
          <th>{intl.formatMessage({ id: "Label" })}</th>
          <th colSpan="2">{intl.formatMessage({ id: "Amount" })}</th>
        </tr>
      </thead>
      <tbody>
        {props.items.map(item => (
          <Item key={item.id} value={item} />
        ))}
      </tbody>
    </Table>
  )
}

function Item(props) {
  const intl = useIntl()
  const { value } = props
  return (
    <tr>
      <td className="text-center align-middle">
        {value.status != "Completed (Success)" &&
          value.status != "Completed (Failure)" &&
          "?"}
        {value.status == "Completed (Success)" && "✓"}
        {value.status == "Completed (Failure)" && "✗"}
      </td>
      <td className="text-center">
        {intl.formatDate(value.created)}
        <br />
        <small>{intl.formatTime(value.created)}</small>
      </td>
      <td className="text-center align-middle">
        {intl.formatMessage({ id: value.typeOfTransaction })}
      </td>
      {(value.typeOfTransaction == "Send" ||
        value.typeOfTransaction == "Receive") && (
        <td>
          {value.address}
          <br />
          <small>
            {intl.formatMessage({ id: "TxId" })}
            <Colon />
            <Space />
            {value.txId}
          </small>
        </td>
      )}
      {value.typeOfTransaction == "Send (Exchange Order)" && (
        <td className="align-middle">
          {intl.formatMessage({ id: "Order" })}
          <Colon />
          <Space />
          {value.order.key}
        </td>
      )}
      {value.typeOfTransaction == "Receive (Exchange Order)" && (
        <td className="align-middle">
          {intl.formatMessage({ id: "Order" })}
          <Colon />
          <Space />
          {value.order.key}
        </td>
      )}
      <td className="text-right">
        {value.amount.toFixed(8)}
        <br />
        <small>
          {intl.formatMessage({ id: "Fee" })}
          <Colon />
          <Space />
          {value.fee}
        </small>
      </td>
      <td className="text-center align-middle">{value.currency}</td>
    </tr>
  )
}

export default withAuth0(WalletTransactions)
