import React from "react"

import { withAuthenticationRequired } from "@auth0/auth0-react"
import { useIntl } from "react-intl"

import Layout from "../components/layout"
import SEO from "../components/seo"
import WalletNavigation from "../components/wallet_navigation"
import WalletTransactions from "../components/wallet_transactions"

const WalletTransactionsPage = ({ location }) => {
  const intl = useIntl()
  const pageTitle = intl.formatMessage({ id: "Transactions" })
  return (
    <Layout pageTitle={pageTitle} location={location}>
      <SEO title={pageTitle} />
      <WalletNavigation location={location} />
      <WalletTransactions />
    </Layout>
  )
}

export default withAuthenticationRequired(WalletTransactionsPage)
